var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useContext, useEffect, useCallback, useMemo } from "react";
import { ActionType, Severity, useMeetingManager, useNotificationDispatch, } from "amazon-chime-sdk-component-library-react";
import { parseJwt } from "../util/auth";
import useWebSocket from "react-use-websocket";
import { useAppState } from "./AppStateProvider";
import routes from "../contants/route";
import { Socket, ViewEnum } from "../types";
import { useNavigation } from "./NavigationProvider";
import { useMediaShare } from "./MediaShareProvider";
var SocketContext = React.createContext(null);
var SocketProvider = function (_a) {
    var children = _a.children;
    var meetingManager = useMeetingManager();
    var dispatch = useNotificationDispatch();
    var params = new URLSearchParams(window.location.search);
    var token = params.get("token");
    var isDesktopView = useNavigation().isDesktopView;
    var closeSnakbar = useMediaShare().closeSnakbar;
    var jwt = parseJwt(token);
    var getSocketUrl = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2, "" + process.env.REACT_APP_WEBSOCKET_URL];
        });
    }); }, []);
    var _b = useAppState(), groupData = _b.groupData, setGroupData = _b.setGroupData, setSpotlightId = _b.setSpotlightId, toggleScreenShare = _b.toggleScreenShare, handleChange = _b.handleChange, meetingInfo = _b.meetingInfo, setappLeaveRoute = _b.setappLeaveRoute;
    var _c = useWebSocket(getSocketUrl, {
        queryParams: {
            userMeetingId: jwt === null || jwt === void 0 ? void 0 : jwt.user_meeting_id,
            attendeeId: meetingInfo === null || meetingInfo === void 0 ? void 0 : meetingInfo.AttendeeId,
        },
        share: false,
        shouldReconnect: function () { return true; },
    }), sendMessage = _c.sendMessage, lastMessage = _c.lastMessage, readyState = _c.readyState, getWebSocket = _c.getWebSocket, sendJsonMessage = _c.sendJsonMessage;
    var socket = getWebSocket();
    useEffect(function () {
        if (socket === null || socket === void 0 ? void 0 : socket.onmessage) {
            socket.onmessage = function (event) { return __awaiter(void 0, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    try {
                        data = JSON.parse(event.data);
                        if (data.action) {
                            switch (data.action) {
                                case Socket.MUTE_LOCAL_AUDIO:
                                    muteAllSocket();
                                    break;
                                case Socket.GROUP:
                                    updateGroupData(data.group_data);
                                    break;
                                case Socket.REMOVED:
                                    setappLeaveRoute(false);
                                    window.location.href = "" + routes.MEETING_END;
                                    break;
                                case Socket.SHARE_SCREEN:
                                    shareScreenSocket(data === null || data === void 0 ? void 0 : data.allowScreenShare);
                                    break;
                                case Socket.VIEW:
                                    if (data.view === ViewEnum.Spotlight) {
                                        setSpotlightId(data.data);
                                        if (!(jwt === null || jwt === void 0 ? void 0 : jwt.is_host)) {
                                            updateGroupData(null);
                                        }
                                    }
                                    if (data.view === ViewEnum.Group) {
                                        setSpotlightId(null);
                                        updateGroupData(data.data);
                                    }
                                    if (data.view === ViewEnum.Gallery) {
                                        if (!(jwt === null || jwt === void 0 ? void 0 : jwt.is_host)) {
                                            updateGroupData(null);
                                        }
                                        setSpotlightId(null);
                                    }
                                    handleChange(data.view);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    catch (err) {
                        console.error(err);
                    }
                    return [2];
                });
            }); };
        }
    }, [socket === null || socket === void 0 ? void 0 : socket.onmessage]);
    var shareScreenSocket = function (isAllowed) {
        toggleScreenShare(isAllowed);
        if (isAllowed) {
            if (jwt === null || jwt === void 0 ? void 0 : jwt.is_host) {
                dispatch({
                    type: ActionType.ADD,
                    payload: {
                        severity: Severity.INFO,
                        message: "Attendees can now share their screen.",
                        autoClose: true,
                        replaceAll: true,
                    },
                });
            }
            else {
                if (isDesktopView === null || isDesktopView === void 0 ? void 0 : isDesktopView.current) {
                    dispatch({
                        type: ActionType.ADD,
                        payload: {
                            severity: Severity.INFO,
                            message: "Screen share allowed to attendees.",
                            autoClose: true,
                            replaceAll: true,
                        },
                    });
                }
            }
        }
        else {
            if (!(jwt === null || jwt === void 0 ? void 0 : jwt.is_host)) {
                closeSnakbar();
            }
            if (isDesktopView === null || isDesktopView === void 0 ? void 0 : isDesktopView.current) {
                dispatch({
                    type: ActionType.ADD,
                    payload: {
                        severity: Severity.INFO,
                        message: "Screen share revoked.",
                        autoClose: true,
                        replaceAll: true,
                    },
                });
            }
        }
    };
    var muteAllSocket = function () {
        var _a;
        if (!(jwt === null || jwt === void 0 ? void 0 : jwt.is_host) && (meetingManager === null || meetingManager === void 0 ? void 0 : meetingManager.audioVideo)) {
            (_a = meetingManager === null || meetingManager === void 0 ? void 0 : meetingManager.audioVideo) === null || _a === void 0 ? void 0 : _a.realtimeMuteLocalAudio();
            dispatch({
                type: ActionType.ADD,
                payload: {
                    severity: Severity.INFO,
                    message: "You have been muted by Host",
                    autoClose: true,
                    replaceAll: true,
                },
            });
        }
    };
    var handleGroupData = function (groupData) {
        sendJsonMessage({
            action: "group",
            group_data: groupData,
            user_meeting_id: jwt === null || jwt === void 0 ? void 0 : jwt.user_meeting_id,
        });
    };
    var handleSetView = function (viewType) {
        switch (viewType) {
            case ViewEnum.Group:
                sendJsonMessage({
                    action: "view",
                    view_type: viewType,
                    data: groupData,
                    user_meeting_id: jwt === null || jwt === void 0 ? void 0 : jwt.user_meeting_id,
                });
                break;
            case ViewEnum.Spotlight:
                sendJsonMessage({
                    action: "view",
                    view_type: viewType,
                    data: meetingInfo.AttendeeId,
                    user_meeting_id: jwt === null || jwt === void 0 ? void 0 : jwt.user_meeting_id,
                });
                break;
            default:
                sendJsonMessage({
                    action: "view",
                    view_type: viewType,
                    data: null,
                    user_meeting_id: jwt === null || jwt === void 0 ? void 0 : jwt.user_meeting_id,
                });
                break;
        }
    };
    var handleMuteUser = function (attendeeId, nameplate) {
        sendJsonMessage({
            action: Socket.MUTE_USER,
            user_meeting_id: jwt === null || jwt === void 0 ? void 0 : jwt.user_meeting_id,
            attendee_id: attendeeId,
        });
        dispatch({
            type: ActionType.ADD,
            payload: {
                severity: Severity.INFO,
                message: nameplate + " is muted.",
                autoClose: true,
                replaceAll: true,
            },
        });
    };
    var handleMuteAll = function () {
        sendJsonMessage({
            action: Socket.MUTE,
            user_meeting_id: jwt === null || jwt === void 0 ? void 0 : jwt.user_meeting_id,
        });
        dispatch({
            type: ActionType.ADD,
            payload: {
                severity: Severity.INFO,
                message: "All participants are muted.",
                autoClose: true,
                replaceAll: true,
            },
        });
    };
    var handleShareScreen = function (allowScreenShare) {
        sendJsonMessage({
            action: Socket.SHARE_SCREEN,
            user_meeting_id: jwt === null || jwt === void 0 ? void 0 : jwt.user_meeting_id,
            allowScreenShare: allowScreenShare,
        });
    };
    var updateGroupData = function (data) {
        setGroupData(data);
    };
    var providerValue = useMemo(function () { return ({
        socket: socket,
        lastMessage: lastMessage,
        readyState: readyState,
        handleShareScreen: handleShareScreen,
        handleMuteAll: handleMuteAll,
        handleGroupData: handleGroupData,
        handleSetView: handleSetView,
        handleMuteUser: handleMuteUser,
        sendJsonMessage: sendJsonMessage,
        sendMessage: sendMessage,
    }); }, [
        socket,
        lastMessage,
        readyState,
        handleShareScreen,
        handleMuteAll,
        handleGroupData,
        handleSetView,
        handleMuteUser,
        sendJsonMessage,
        sendMessage,
    ]);
    return (React.createElement(SocketContext.Provider, { value: providerValue }, children));
};
var useSocketProvider = function () {
    var context = useContext(SocketContext);
    if (!context) {
        throw Error("Use useSocketProvider in SocketProvider");
    }
    return context;
};
export { SocketProvider, useSocketProvider };
