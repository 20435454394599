var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useEffect, useContext, useRef, useMemo, } from "react";
import { useActiveSpeakersState, useAudioVideo, useRosterState, } from "amazon-chime-sdk-component-library-react";
import ActiveSpeakerPolicy from "./ActiveSpeakerPolicy";
var context = React.createContext(null);
export function getCounterContext() {
    return context;
}
var ActiveSpeakerProvider = function (_a) {
    var children = _a.children;
    var ActiveSpeakerContext = getCounterContext();
    var audioVideo = useAudioVideo();
    var activeSpeakers = useActiveSpeakersState();
    var _b = useState({}), volumes = _b[0], setVolumes = _b[1];
    var _c = useState(), selectedUsers = _c[0], setSelectedUsers = _c[1];
    var _d = useState(""), displayUsers = _d[0], setDisplayUsers = _d[1];
    var roster = useRosterState().roster;
    var intervalId = useRef(null);
    var policy = useRef(null);
    var onScoreChange = function (newVolume) {
        var newVolumes = __assign(__assign({}, volumes), newVolume);
        if (JSON.stringify(volumes) !== JSON.stringify(newVolumes)) {
            setVolumes(__assign(__assign({}, volumes), newVolume));
        }
    };
    useEffect(function () {
        var _a;
        if (roster && activeSpeakers && volumes) {
            var newSelectedUsers = (_a = activeSpeakers === null || activeSpeakers === void 0 ? void 0 : activeSpeakers.filter(function (attendeeId) {
                var _a, _b;
                return (roster === null || roster === void 0 ? void 0 : roster[attendeeId]) &&
                    ((_a = volumes === null || volumes === void 0 ? void 0 : volumes[attendeeId]) === null || _a === void 0 ? void 0 : _a.volume) > 0.01 &&
                    !((_b = volumes === null || volumes === void 0 ? void 0 : volumes[attendeeId]) === null || _b === void 0 ? void 0 : _b.muted);
            })) === null || _a === void 0 ? void 0 : _a.map(function (attendeeId) { return roster === null || roster === void 0 ? void 0 : roster[attendeeId]; });
            if (JSON.stringify(newSelectedUsers) !== JSON.stringify(selectedUsers)) {
                setSelectedUsers(newSelectedUsers);
            }
        }
    }, [roster, volumes, activeSpeakers]);
    useEffect(function () {
        if (selectedUsers === null || selectedUsers === void 0 ? void 0 : selectedUsers.length) {
            if (intervalId === null || intervalId === void 0 ? void 0 : intervalId.current) {
                clearInterval(intervalId.current);
            }
            setDisplayUsers(selectedUsers.map(function (user) { return user.name; }).join(","));
        }
        else {
            if (intervalId) {
                intervalId.current = setTimeout(function () {
                    setDisplayUsers("");
                }, 400);
            }
        }
        return function () {
            if (intervalId === null || intervalId === void 0 ? void 0 : intervalId.current) {
                clearInterval(intervalId.current);
            }
        };
    }, [selectedUsers]);
    useEffect(function () {
        var activeSpeakerCallback = function () { };
        if (audioVideo && roster && !policy.current) {
            policy.current = new ActiveSpeakerPolicy(0.9, 0.01, 0.2, 0.2, onScoreChange);
            audioVideo.subscribeToActiveSpeakerDetector(policy.current, activeSpeakerCallback);
        }
        return function () {
            if (policy.current) {
                policy.current = null;
                audioVideo === null || audioVideo === void 0 ? void 0 : audioVideo.unsubscribeFromActiveSpeakerDetector(activeSpeakerCallback);
            }
        };
    }, [audioVideo, roster]);
    var providerValue = useMemo(function () { return ({
        selectedUsers: selectedUsers,
        displayUsers: displayUsers,
    }); }, [selectedUsers, displayUsers]);
    return (React.createElement(ActiveSpeakerContext.Provider, { value: providerValue }, children));
};
var useActiveSpeaker = function () {
    var counterContext = useContext(context);
    if (!counterContext) {
        throw Error("Use useCounter in NavigationProvider");
    }
    return counterContext;
};
export { ActiveSpeakerProvider, useActiveSpeaker };
